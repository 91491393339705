import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";
import transformFlows from "@/services/flowService";

export default function useIvrs() {
  const t = inject("t");
  const store = useStore();
  const ivr = ref({
    name: "",
    is_active: null,
  });
  const loading = ref(true);
  const ivrs = ref([]);
  const actions = ref([]);
  const ivrVersion = ref(null);
  const ivrVersions = ref([]);
  const dynamicOptions = ref([]);
  const notify = inject("notify");
  const selectText = t("xpbx.settings.dropdown.select_option");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  // GET ACTIONS
  const getActions = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/flow-actions");

      if (response?.data) {
        store.commit("xadmin/SET_XADMIN_ACTIONS", response.data);
        actions.value = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  //   FIND IVRS
  const findIvrs = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/flow");

      if (response?.data) ivrs.value = response.data;
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  //   FIND IVRS MEMBERS
  const findIvrVersions = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/flow/${id}/version`);

      if (response?.data) ivrVersions.value = response.data;
    } catch (error) {}
  };

  //   FIND IVR
  const findIvr = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/flow/${id}`);

      if (response?.data)
        ivr.value = {
          ...response.data,
          is_active: response.data.is_active === 1 ? true : false,
        };
    } catch (error) {}
  };

  //   CREATE IVR
  const createIvr = async (data) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(`/admin/xpbx/settings/flow`, data);

      if (response?.data) {
        const ivrData = {
          ...response.data,
          is_active: response.data.is_active === 1 ? true : false,
        };

        ivrs.value = [ivrData, ...ivrs.value];
        n(
          "xpbx.settings.ivrs_general.notifications.create_ivr_general",
          "success"
        );
      } else {
        n("xpbx.settings.ivrs_general.notifications.error_create_ivr_general");
      }
    } catch (error) {
      n("xpbx.settings.ivrs_general.notifications.error_create_ivr_general");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   CREATE IVR VERSION
  const createIvrVersion = async (data, id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(
        `/admin/xpbx/settings/flow/${id}/version`,
        data
      );

      if (response?.data) {
        const responseData = {
          ...response.data,
          created_at: new Date(),
          updated_at: new Date(),
        };

        ivrVersions.value = [responseData, ...ivrVersions.value];
        n(
          "xpbx.settings.ivrs_version.notifications.create_ivr_version",
          "success"
        );
      } else {
        n("xpbx.settings.ivrs_version.notifications.error_create_ivrs_version");
      }
    } catch (error) {
      n("xpbx.settings.ivrs_version.notifications.error_create_ivrs_version");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //  UPDATE IVR VERSION NAME
  const updateIvrVersionName = async (data, id, versionId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/flow/${id}/version/${versionId}`,
        data
      );

      if (response?.data) {
        const index = ivrVersions.value.findIndex((i) => i.id === versionId);
        ivrVersions.value[index] = {
          ...ivrVersions.value[index],
          name: response.data.name,
        };
        n(
          "xpbx.settings.ivrs_version.notifications.update_ivr_version",
          "success"
        );
      } else {
        n("xpbx.settings.ivrs_version.notifications.error_update_ivrs_version");
      }
    } catch (error) {
      n("xpbx.settings.ivrs_version.notifications.error_update_ivrs_version");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //  UPDATE IVR VERSION
  const updateIvrVersion = async (data, id, versionId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/flow/${id}/version/${versionId}`,
        data
      );

      if (response?.data) {
        const index = ivrVersions.value.findIndex((i) => i.id === versionId);
        ivrVersions.value[index] = {
          ...ivrVersions.value[index],
          name: response.data.name,
        };
        n(
          "xpbx.settings.ivrs_version.notifications.update_ivr_version",
          "success"
        );
      } else {
        n("xpbx.settings.ivrs_version.notifications.error_update_ivrs_version");
      }
    } catch (error) {
      n("xpbx.settings.ivrs_version.notifications.error_update_ivrs_version");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   UPDATE IVR
  const updateIvr = async (data, id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(`/admin/xpbx/settings/flow/${id}`, data);

      if (response?.data?.id) {
        const index = ivrs.value.findIndex((i) => i.id === id);
        ivrs.value[index] = response.data;
        n(
          "xpbx.settings.ivrs_general.notifications.edit_ivr_general",
          "success"
        );
      } else {
        n("xpbx.settings.ivrs_general.notifications.error_edit_ivr_general");
      }
    } catch (error) {
      n("xpbx.settings.ivrs_general.notifications.error_edit_ivr_general");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   CLONE IVR
  const cloneIvr = async (id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.get(`/admin/xpbx/settings/flow/${id}/clone`);

      if (response.data) {
        n("xpbx.settings.ivrs_general.notifications.clone_ivr", "success");

        ivrs.value = [response.data, ...ivrs.value];
      } else {
        n("xpbx.settings.ivrs_general.notifications.error_clone_ivr");
      }
    } catch (error) {
      n("xpbx.settings.ivrs_general.notifications.error_clone_ivr");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   CLONE IVR VERSION
  const cloneIvrVersion = async (id, versionId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.get(
        `/admin/xpbx/settings/flow/${id}/version/${versionId}/clone`
      );

      if (response.data) {
        n(
          "xpbx.settings.ivrs_version.notifications.clone_ivr_version",
          "success"
        );

        ivrVersions.value = [response.data, ...ivrVersions.value];
      } else {
        n("xpbx.settings.ivrs_version.notifications.error_clone_ivr_version");
      }
    } catch (error) {
      n("xpbx.settings.ivrs_version.notifications.error_clone_ivr_version");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // ACTIVATE IVR VERSION
  const activateIvrVersion = async (id, versionId, form) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/flow/${id}/version/${versionId}/activate`,
        form
      );

      if (response.data) {
        ivrVersions.value = [];
        ivrVersions.value = response.data;
        n(
          "xpbx.settings.ivrs_version.notifications.activate_ivr_version",
          "success"
        );
      } else {
        n(
          "xpbx.settings.ivrs_version.notifications.error_activate_ivr_version"
        );
      }
    } catch (error) {
      n("xpbx.settings.ivrs_version.notifications.error_activate_ivr_version");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   UPDATE IVR VERSION
  const updateIvrVersionSettings = async (id, versionId, data) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/flow/${id}/version/${versionId}`,
        data
      );

      if (response.data) {
        n(
          "xpbx.settings.ivrs_version.notifications.update_ivr_version_settings",
          "success"
        );

        // ivrVersions.value.push(response.data);
      } else {
        n(
          "xpbx.settings.ivrs_version.notifications.error_update_ivr_version_settings"
        );
      }
    } catch (error) {
      n(
        "xpbx.settings.ivrs_version.notifications.error_update_ivr_version_settings"
      );
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  const setIVRVersionSettings = async (data) => {
    const fetchedNodes = transformFlows(data);

    const { nodesData, edgesData, zoomData } = fetchedNodes || {};

    const positionData = {
      x: 0,
      y: 0,
      zoom: 1,
    };

    if (zoomData) {
      const [a, b, c, d, e, f] = zoomData.slice(7, -1).split(", ").map(Number);

      positionData.x = e;
      positionData.y = f;
      positionData.zoom = a;
    }

    store.commit("xadmin/SET_XADMIN_VERSION", data);
    store.commit("xadmin/SET_XADMIN_NODES", nodesData);
    store.commit("xadmin/SET_XADMIN_EDGES", edgesData);
    store.commit("xadmin/SET_XADMIN_FLOW_POSITION", positionData);

    return fetchedNodes;
  };

  // GET IVR VERSION
  const getIvrVersionData = async (id, versionId) => {
    if (!id || !versionId) return null;

    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.get(
        `/admin/xpbx/settings/flow/${id}/version/${versionId}`
      );

      if (response?.data) {
        ivrVersion.value = response.data;
        // Get  Data from Response
        const fetchedNodes = setIVRVersionSettings(response.data);
        return fetchedNodes;
      }
    } catch (error) {
      console.log(error);
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE IVR
  const deleteIvr = async (id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      await api.delete(`/admin/xpbx/settings/flow/${id}`);
      n("xpbx.settings.ivrs_version.notifications.delete_ivr", "success");

      ivrs.value = ivrs.value.filter((i) => i.id !== id);
    } catch (error) {
      n("xpbx.settings.ivrs_version.notifications.error_delete_ivr");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE IVR VERSION
  const deleteIvrVersion = async (id, versionId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(
        `/admin/xpbx/settings/flow/${id}/version/${versionId}`
      );

      if (response.status === 200) {
        n(
          "xpbx.settings.ivrs_version.notifications.delete_ivr_version",
          "success"
        );

        ivrVersions.value = ivrVersions.value.filter((i) => i.id !== versionId);
      } else {
        n("xpbx.settings.ivrs_version.notifications.error_delete_ivr_version");
      }
    } catch (error) {
      n("xpbx.settings.ivrs_version.notifications.error_delete_ivr_version");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   GET IVR DYNAMIC OPTIONS
  const getIVRDynamicOptions = async (url, options) => {
    try {
      const response = await api.get(url);

      if (response?.data?.length) {
        let optionsData = [];

        const mapArray = options.value?.map;

        const defaultOption = {};

        for (const key in mapArray) {
          const objectKey = mapArray[key];
          if (objectKey === "id") {
            defaultOption[objectKey] = null;
          } else {
            defaultOption[objectKey] = selectText;
          }
        }

        for (const value of response?.data) {
          const option = {};
          for (const map of mapArray) {
            option[map] = value[map];
          }

          optionsData.push(option);
        }

        optionsData = [defaultOption, ...optionsData];

        dynamicOptions.value = optionsData;
      }

      return response?.data || [];
    } catch (error) {
      return [];
    }
  };

  return {
    ivr,
    ivrs,
    loading,
    actions,
    ivrVersion,
    ivrVersions,
    dynamicOptions,

    findIvrs,
    findIvr,
    getActions,
    cloneIvr,
    createIvr,
    updateIvr,
    deleteIvr,
    findIvrVersions,
    createIvrVersion,
    updateIvrVersion,
    deleteIvrVersion,
    cloneIvrVersion,
    getIvrVersionData,
    activateIvrVersion,
    updateIvrVersionName,
    updateIvrVersionSettings,
    getIVRDynamicOptions,
  };
}
