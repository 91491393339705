<template>
  <section>
    <div
      class="card relative table-wrapper"
      :class="{ 'table-none': !ivrVersions?.length }"
    >
      <DataTable
        ref="dt"
        scrollable
        :value="ivrVersions"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        dataKey="id"
        selectionMode="single"
        v-model:selection="selectedRecords"
      >
        <template #empty>
          {{ $t("xpbx.settings.queue-vnumbers.fields.empty_table") }}</template
        >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                class="mr-2 add-record-button"
                @click="openNew"
                :label="$t('xpbx.button.new')"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>
        <Column
          sortable
          style="width: 70%"
          field="name"
          :header="$t('xpbx.settings.ivr_versions.fields.name')"
        >
          <template #body="{ data }">
            <div>
              {{ data?.name }}
            </div>
            <div class="flex items-center">
              <!-- <i
                v-tooltip.top="$t('xpbx.settings.tooltips.clone_ivr_version')"
                class="fa-light fa-clone edit-icon mx-2 clone-icon"
                @click="cloneIVR(data?.id)"
              ></i> -->

              <TableActions
                :data="data"
                :id="data.id"
                @edit="editHandle"
                @setActive="setActive"
                @clone="cloneIVR"
                :showClone="true"
                :isActive="data.is_active"
                :link="`/xpbx/settings/flow/${$route?.params?.id}/version/${data?.id}`"
                @delete="deleteSelectedRecord"
              />
            </div>
          </template>
        </Column>

        <Column
          sortable
          field="created_at"
          filterField="created_at"
          dataType="date"
          style="width: 10%"
          :header="$t('xpbx.settings.ivr_versions.fields.created_at')"
        >
          <template #body="slotProps">
            <DateItem :value="slotProps?.data?.created_at" />
          </template>
        </Column>
        <Column
          sortable
          dataType="date"
          field="updated_at"
          filterField="updated_at"
          style="width: 10%"
          :header="$t('xpbx.settings.ivr_versions.fields.updated_at')"
        >
          <template #body="slotProps">
            <DateItem :value="slotProps?.data?.updated_at" />
          </template>
        </Column>
      </DataTable>

      <p class="text-center my-2" v-if="!ivrVersions?.length">
        {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}
      </p>
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.name">
          {{
            $t("xpbx.settings.ivr_versions.notification.confirm_delete", {
              delete: `version ${selectedRecord?.name}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          text
          @click="deleteDialog = false"
          :label="$t('xpbx.button.no')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.button.yes')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '500px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.ivr_versions.dialogs.edit_ivr_version')
          : $t('xpbx.settings.ivr_versions.dialogs.create_ivr_version')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!--Name -->
      <div class="field mb-1">
        <label for="name">{{
          $t("xpbx.settings.ivr_versions.labels.name")
        }}</label>
        <InputText
          id="name"
          v-model.trim="record.name"
          required="true"
          autofocus
          :class="{ 'p-invalid': submitted && !record.name }"
        />
        <small class="p-error" v-if="!!errors?.name?.[0]">{{
          errors?.name?.[0]
        }}</small>
      </div>
      <QueueHint :title="$t('xpbx.settings.ivr_versions.hints.name')" />

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="createDialog = false"
        />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Tooltip from "primevue/tooltip";
import InputText from "primevue/inputtext";
import InputSwitch from "primevue/inputswitch";
import validator from "@/composables/auth/validator";
import useIvrs from "@/modules/xpbx/composables/useIvrs";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
import { validateCreateIVR } from "@/composables/auth/createDomainValidations";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "FlowVersions",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    InputText,
    InputSwitch,
    QueueHint,
    DateItem,
    TableActions,
    DialogButtons,
  },

  directives: {
    tooltip: Tooltip,
  },

  setup() {
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const record = ref({});
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);

    const {
      ivrVersions,
      findIvrVersions,
      createIvrVersion,
      deleteIvrVersion,
      cloneIvrVersion,
      activateIvrVersion,
      updateIvrVersionName,
    } = useIvrs();

    // Data table functions
    const openNew = () => {
      record.value = {
        name: "",
        is_active: false,
      };
      createDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      record.value = {
        name: "",
        is_active: false,
      };
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      confirmDeleteSelected();
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.id;

      if (selectedRecord.value.id) {
        await deleteIvrVersion(id, selectedRecord.value.id);
      }
      deleteDialog.value = false;
    };

    const createRecord = async (data) => {
      const isValid = validateCreateIVR(data);

      if (isValid) {
        const id = route.params.id;

        await createIvrVersion(data, id);
        createDialog.value = false;
      }
    };

    const editHandle = (data) => {
      record.value = {
        name: data.name,
        is_active: data.is_active === 1 ? true : false,
      };
      isEdit.value = true;
      selectedRecord.value = data;
      createDialog.value = true;
    };

    const updateRecord = async () => {
      const id = route.params.id;
      const versionId = selectedRecord.value.id;

      const formData = {
        name: record.value.name,
        is_active: record.value.is_active === true ? 1 : 0,
      };

      await updateIvrVersionName(formData, id, versionId);
      createDialog.value = false;
    };

    const create = async () => {
      if (isEdit.value) {
        // Update
        await updateRecord();
      } else {
        submitted.value = true;
        await createRecord(record.value);
      }
    };

    const cloneIVR = async (versionId) => {
      const id = route.params.id;
      await cloneIvrVersion(id, versionId);
    };

    const setActive = async (data) => {
      const id = route.params.id;
      const formData = {
        is_active: data.is_active === 1 ? 0 : 1,
      };

      await activateIvrVersion(id, data.id, formData);
    };

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      // checkTableClick();
      await findIvrVersions(route.params.id);
    });

    return {
      ivrVersions,
      submitted,
      record,
      isEdit,
      errors,
      clearErrors,
      scrollHeight,
      createDialog,
      deleteDialog,
      selectedRecord,
      selectedRecords,
      // Methods
      reset,
      create,
      openNew,
      editHandle,
      cloneIVR,
      setActive,
      closeDialog,
      updateRecord,
      findIvrVersions,
      deleteIvrVersion,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}

.edit-icon {
  color: #10b981;
}

.clone-icon {
  font-size: 1.1rem;
}

.inactive {
  transform: rotate(180deg);
}
</style>