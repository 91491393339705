import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const { checkRequired, errors, clearErrors, isValidUrl } = validator();

export function validateCreateDomain(data) {
  clearErrors();

  data = unref(data);

  checkRequired("domain", data);
  checkRequired("country", data);
  return hasErrors.value ? false : true;
}

export function validateCreateQueue(data) {
  clearErrors();

  data = unref(data);

  let strategy = data;
  let weight = data;
  let timeout = data;
  let wrapuptime = data;
  let findAgentPeriod = data;

  if (data.weight?.name) weight = data.weight;
  if (data.wrapuptime?.name) wrapuptime = data.weight;
  if (data.strategy?.name) strategy = data.strategy;
  if (data.timeout?.name) timeout = data.timeout;
  if (data.find_agent_period?.name) findAgentPeriod = data.find_agent_period;

  checkRequired("name", data);
  checkRequired("strategy", strategy);
  checkRequired("weight", weight);
  checkRequired("timeout", timeout);
  checkRequired("queue_number", data);
  checkRequired("wrapuptime", wrapuptime);
  checkRequired("find_agent_period", findAgentPeriod);
  return hasErrors.value ? false : true;
}

export function validateCreateRingGroup(data) {
  clearErrors();

  data = unref(data);

  let ringTime = data;
  let musicOnHold = data;
  let ringStrategy = data;

  if (data.ring_time?.name) ringTime = data.ring_time;
  if (data.music_on_hold?.name) musicOnHold = data.music_on_hold;
  if (data.ring_strategy?.name) ringStrategy = data.ring_strategy;

  checkRequired("name", data);
  checkRequired("ring_time", ringTime);
  checkRequired("ring_strategy", ringStrategy);
  checkRequired("music_on_hold", musicOnHold);
  return hasErrors.value ? false : true;
}

export function validateCreateIVR(data) {
  clearErrors();

  data = unref(data);

  checkRequired("name", data);
  return hasErrors.value ? false : true;
}
