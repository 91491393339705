<template>
  <section class="w-full">
    <div class="relative flex gap-4" v-if="isLoaded">
      <div class="w-2/4">
        <!--Name -->
        <div class="field flex items-start flex-col">
          <label for="name">{{
            $t("xpbx.settings.ivrs_general.labels.name")
          }}</label>
          <InputText
            id="name"
            class="w-full"
            v-model.trim="ivr.name"
            required="true"
            autofocus
          />
        </div>
        <QueueHint :title="$t('xpbx.settings.ivrs_general.hints.name')" />

        <!--Is active -->
        <div class="mt-3">
          <InputSwitch
            v-model:modelValue="ivr.is_active"
            :label="$t('xpbx.settings.ivrs_general.labels.is_active')"
          />
        </div>

        <Divider class="mt-4" />

        <div class="flex justify-start">
          <Button
            class="mr-2 add-record-button"
            @click="edit"
            :label="$t('xpbx.settings.ring_group_general.buttons.save')"
          />
        </div>
      </div>

      <!-- Right container -->
      <div class="w-2/5 ml-2"></div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Divider from "primevue/divider";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import useIvrs from "@/modules/xpbx/composables/useIvrs";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "FlowGeneral",

  components: {
    Button,
    InputText,
    InputSwitch,
    QueueHint,
    Divider,
  },

  setup() {
    const route = useRoute();
    const isLoaded = ref(false);
    const { ivr, findIvr, updateIvr } = useIvrs();

    const edit = async () => {
      const data = {
        name: ivr.value.name,
        is_active: ivr.value.is_active ? 1 : 0,
      };

      await updateIvr(data, route.params.id);
    };

    onMounted(async () => {
      await findIvr(route.params.id);

      isLoaded.value = true;
    });

    return {
      ivr,
      isLoaded,
      //   Methods
      edit,
    };
  },
};
</script>