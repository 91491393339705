import { Position } from "@vue-flow/core";

export default function transformFlows(flowData) {
  //  From hard coded data

  //   From response.data
  const data = flowData?.schema;

  //   Get Zoom
  const zoom = data?.panzoom || null;

  //   Get Nodes
  const nodes = transformNodes(data?.menus);

  //   Get Edges
  const edges = transformEdges(data?.links);

  return { nodesData: nodes, zoomData: zoom, edgesData: edges };
}

const transformNodes = (nodes) => {
  if (!nodes) return [];

  return nodes.map((node) => {
    return {
      id: node.id.toString(),
      type: "custom",
      label:
        node?.label || node?.menu_options?.description || `Node ${node.id}`,
      action_id: node?.action_id || null,
      data: {
        action_id: node?.action_id || null,
        inputs: node.inputs || [],
        outputs: node.outputs || [],
        dynamicInputs: node?.dynamicInputs ? node.dynamicInputs : false,
        dynamicOutputs: node?.dynamicOutputs ? node.dynamicOutputs : false,
        menuOptions: node?.menu_options || null,
      },
      position: { x: node.flowchart.left, y: node.flowchart.top },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    };
  });
};

const transformEdges = (edges) => {
  if (!edges) return [];
  console.log("transformEdges", edges);

  return edges.map((edge, index) => {
    return {
      id: `${index}`,
      type: "custom",
      source: `${edge.dst?.connector_id}`,
      target: `${edge.dst?.menu_id}`,
      sourceHandle: `${edge.dst?.sourceHandle}`,
      targetHandle: `${edge.dst?.targetHandle}`,
      // label: `${edge.dst?.connector_id} to ${edge.dst?.menu_id}`, // The text you want to display on the edge
      style: { stroke: "#10b981" }, // Optional: custom styling for the edge
      labelStyle: { fill: "#10b981", fontWeight: 700 }, // Optional: custom styling for the label
      labelBgStyle: { fill: "white" }, // Optional: background style for the label
      labelBgPadding: [6, 3], // Optional: padding around the label
      labelBgBorderRadius: 3, // Optional: border-radius for the label background
    };
  });
};
